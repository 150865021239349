import React, { FC, ReactElement, useMemo, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import Spinner from 'ui-kit/spinner/spinner';
import { ShippingAddressesProps } from 'components/shipping-addresses/shipping-addresses.props';
import './shipping-addresses.style.scss';
import AddressCard from 'components/shipping-addresses/address-card/address-card.component';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileObjectAddressPayload } from 'state/account/account.services';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import AddAddressForm, {
    AddressFormSchema,
    AddressVerificationFailureModalContent
} from 'components/add-address-form/AddAddressForm';
import { AddressParts } from 'state/usps/usps.reducers';
import { useAddressVerification } from 'hooks/useAddressVerification';
import {
    accountAddAddressToProfileRoutine,
    accountRemoveAddressFromProfileRoutine,
    accountSetPrimaryAddressRoutine,
    accountUpdateAddressToProfileRoutine
} from 'state/account/account.routines';
import { AddressCardProps } from './address-card/address-card.props';
import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent
} from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';
import { accountProfileAddressesSelector } from 'state/account/account.selectors';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';

const DeleteAddressModalContent = ({
    address,
    translation
}: {
    address: AddressCardProps;
    translation: TFunction<'translation'>;
}) => {
    return (
        <BirdiModalContentAlt
            subTitle={translation('modals.changeAddressModal.body', {
                address: `<strong>${address.address1}, ${address.city}, ${address.state} ${address.zipcode}</strong>`
            })}
        />
    );
};

const ShippingAddresses: FC<ShippingAddressesProps> = ({
    addressData,
    onSetAsShipping,
    isProfile,
    showLabels = true,
    buttonVariant = 'primary',
    addNewAddressButtonLabel
}: ShippingAddressesProps): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isAddressBusy, setIsAddressBusy] = useState(false);
    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const { isBusy: isAddAddressBusy, verifyAddress } = useAddressVerification();
    const sortDefaultAddresses = useMemo(() => {
        // Add defaultAddress boolean to first address automatically to be addressed in DRX-115
        return addressData.sort((a, b) => Number(b.defaultAddress) - Number(a.defaultAddress));
    }, [addressData]);

    const handleAddAddressFormCancel = () => {
        dispatch(closeModal({}));
    };

    const handleAddNewAddressClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'address-verification-modal small-footer',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        body={
                            <AddAddressForm
                                title={t('modals.addAddressModal.title')}
                                handleFormCancel={handleAddAddressFormCancel}
                                handleFormSubmit={handleAddAddressFormSubmit}
                                centerFormSubmit={true}
                                isAddressVerifying={isAddAddressBusy}
                                showSetAsDefault={true}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const handleAddAddressFormSubmit = (values: ProfileObjectAddressPayload) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };
        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                setIsAddressBusy(true);
                dispatch(
                    accountAddAddressToProfileRoutine.trigger({
                        ...values,
                        zipcode: Zip5,
                        zipcodeFour: Zip4,
                        onSuccess: () => {
                            setIsAddressBusy(false);
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    type: 'danger',
                                    size: 'lg',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            icon="alert"
                                            headerText={t('modals.updateProfile.error')}
                                        />
                                    ),
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.address')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'UpdateProfileError'
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
                dispatch(closeModal({}));
            },
            onFailure: ({ response, errors }) => {
                setIsAddressBusy(false);
                if (values.onFailure && errors && !errors.error) {
                    values.onFailure(errors);
                } else {
                    dispatch(
                        openModal({
                            showClose: true,
                            type: 'danger',
                            size: 'lg',
                            headerContent: (
                                <BirdiModalHeaderDanger
                                    headerText={t('modals.addressVerificationFailure.title')}
                                    icon="alert"
                                />
                            ),
                            bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.addressVerificationFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        handleAddNewAddressClick(values);
                                    },
                                    dataGALocation: 'AddressNotVerified'
                                }
                            ]
                        })
                    );
                }
            }
        });
    };

    const handleShippingAddressChange = (address: AddressCardProps) => {
        setIsAddressBusy(true);
        dispatch(
            accountSetPrimaryAddressRoutine({
                ...address,
                onSuccess: () => {
                    setIsAddressBusy(false);
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: isProfile ? (
                                <UpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                            ) : (
                                <BirdiModalContent
                                    icon={'success'}
                                    title={t('modals.updateProfile.title')}
                                    body={t('modals.updateCart.defaultAddressUpdated')}
                                />
                            ),
                            ctas: [
                                {
                                    label: t('modals.updateProfile.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: t('modals.updateProfile.title')
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    setIsAddressBusy(false);
                    dispatch(
                        openModal({
                            showClose: true,
                            size: 'lg',
                            type: 'danger',
                            headerContent: (
                                <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                            ),
                            bodyContent: (
                                <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                            ),
                            ctas: [
                                {
                                    label: t('modals.updateProfile.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'UpdateProfileError'
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handleOpenDeleteModal = (address: AddressCardProps) => {
        dispatch(
            openModal({
                showClose: true,
                size: 'lg',
                type: 'danger',
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.changeAddressModal.title')} icon="alert" />
                ),
                bodyContent: <DeleteAddressModalContent address={address} translation={t} />,
                ctas: [
                    {
                        label: 'Confirm',
                        variant: 'primary',
                        onClick: () => {
                            setIsAddressBusy(true);
                            dispatch(
                                accountRemoveAddressFromProfileRoutine({
                                    address: address,
                                    onSuccess: () => {
                                        setIsAddressBusy(false);
                                    },
                                    onFailure: () => {
                                        setIsAddressBusy(false);
                                    }
                                })
                            );
                            dispatch(closeModal({}));
                        },
                        dataGALocation: t('modals.changeAddressModal.title')
                    }
                ]
            })
        );
    };

    const handleEditAddressClick = (address: AddressCardProps) => {
        const editAddress: AddressFormSchema =
            address.zipcodeFour === ''
                ? { ...address, addressTypeDesc: address.addressType }
                : {
                      ...address,
                      zipcode: `${address.zipcode}-${address.zipcodeFour}`,
                      addressTypeDesc: address.addressType
                  };
        const editAddressIndex = profileAddresses.findIndex(
            (profileAddress) =>
                profileAddress.address1 === address.address1 &&
                profileAddress.address2 === address.address2 &&
                profileAddress.city === address.city &&
                profileAddress.state === address.state &&
                profileAddress.zipcode === address.zipcode + '' &&
                profileAddress.zipcodeFour === address.zipcodeFour + ''
        );

        dispatch(
            openModal({
                showClose: true,
                className: 'address-verification-modal small-footer',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        body={
                            <AddAddressForm
                                title={t('modals.editAddressModal.title')}
                                defaultValues={editAddress}
                                handleFormCancel={() => {}}
                                handleFormSubmit={(values) => {
                                    handleEditAddressSubmit(values, editAddressIndex);
                                }}
                                centerFormSubmit={true}
                                showCancel={false}
                                isAddressVerifying={isAddAddressBusy}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const handleEditAddressSubmit = (values: ProfileObjectAddressPayload, editAddressIndex: Number) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };

        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                const address = { ...values, zipcode: Zip5, zipcodeFour: Zip4 };
                dispatch(
                    accountUpdateAddressToProfileRoutine({
                        index: editAddressIndex,
                        address,
                        onSuccess: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <UpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            }
                                        }
                                    ]
                                })
                            );
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    size: 'lg',
                                    type: 'danger',
                                    headerContent: (
                                        <BirdiModalHeaderDanger
                                            headerText={t('modals.updateProfile.error')}
                                            icon="alert"
                                        />
                                    ),
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.address')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            }
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
            },
            onFailure: ({ response, errors }) => {
                if (values.onFailure && errors && !errors.error) {
                    values.onFailure(errors);
                } else {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.addressVerificationFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            }
        });
    };

    return (
        <>
            <Spinner isVisible={isAddressBusy} t={t} />
            {sortDefaultAddresses.length === 0 && (
                <Container fluid className="my-4">
                    <p>{t('pages.profile.shippingAddress.empty')}</p>
                </Container>
            )}
            {sortDefaultAddresses.length > 0 && (
                <Container fluid className="mt-4">
                    <Row xs={1} md={1} lg={2} xl={2} className="gy-3 d-flex">
                        {sortDefaultAddresses.map((value, index) => {
                            return (
                                <AddressCard
                                    key={index}
                                    index={index}
                                    defaultAddress={value.defaultAddress}
                                    address1={value.address1}
                                    address2={value.address2}
                                    city={value.city}
                                    state={value.state}
                                    zipcode={value.zipcode}
                                    zipcodeFour={value.zipcodeFour}
                                    isChecked={value.isChecked}
                                    openDeleteModal={() => handleOpenDeleteModal(value)}
                                    openEdit={() => handleEditAddressClick(value)}
                                    addressType={value.addressType}
                                    isProfile={isProfile}
                                    showLabel={showLabels}
                                    onSetAsDefault={() => handleShippingAddressChange(value)}
                                    handleSetAsShipping={onSetAsShipping ? () => onSetAsShipping(value) : undefined}
                                />
                            );
                        })}
                    </Row>
                </Container>
            )}
            {addNewAddressButtonLabel && !isProfile && (
                <Container fluid className="add-address-btn-container">
                    <Button
                        plusIcon
                        IconType="secondary"
                        className={`${buttonVariant === 'primary' ? 'sm-full' : 'p-0'}`}
                        label={addNewAddressButtonLabel}
                        type="button"
                        variant={buttonVariant}
                        onClick={handleAddNewAddressClick}
                        dataGAFormName="addresses"
                    />
                </Container>
            )}
            {addNewAddressButtonLabel && isProfile && (
                <Container fluid className="add-address-btn-container">
                    <Button
                        className={`${buttonVariant === 'primary' ? 'sm-full' : 'p-0'}`}
                        label={addNewAddressButtonLabel}
                        type="button"
                        variant={buttonVariant}
                        onClick={handleAddNewAddressClick}
                        dataGAFormName="addresses"
                    />
                </Container>
            )}
        </>
    );
};

export default ShippingAddresses;
