import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ProfileLayout from 'components/layouts/profile/profile.layout';
import { useSelector } from 'react-redux';
import ShippingAddresses from 'components/shipping-addresses/shipping-addresses.component';
import { accountProfileAddressesSelector } from 'state/account/account.selectors';
import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';
import { medicineCabinetAutoRefillPlanEligibleSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

const ShippingAddress = () => {
    const { t } = useTranslation();
    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const addresses: AddressCardProps[] = profileAddresses.map((address) => ({
        defaultAddress: address.currentShipping,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        country: address.country,
        state: address.state,
        zipcode: address.zipcode,
        zipcodeFour: address.zipcodeFour,
        defaultAddressLabel: t('shipping.shipToThisAddressLabel'),
        addressType: address.addressTypeDesc,
        isProfile: true
    }));
    const displayAutoRefillMessage = useSelector(medicineCabinetAutoRefillPlanEligibleSelector);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.eyebrowText`)}
            title={t(`pages.profile.shippingAddress.title`)}
            heading={t(`pages.profile.shippingAddress.heading`)}
        >
            {displayAutoRefillMessage && (
                <div className="px-4">{t('pages.profile.shippingAddress.autoRefillSubhead')}</div>
            )}
            <ShippingAddresses
                addressData={addresses}
                isProfile={true}
                addNewAddressButtonLabel={t('pages.profile.shippingAddress.labels.addNewAddress')}
            />
        </ProfileLayout>
    );
};

export default ShippingAddress;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
