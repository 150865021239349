import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';
import './address-card.style.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';

//
// --- Helper Functions ---

function formatAddressType(addressType: string) {
    return addressType
        .split('')
        .map((char, i) => (i === 0 ? char : char.toLowerCase()))
        .join('');
}

//
// --- AddressCard Component ---

const AddressCard: React.FC<AddressCardProps> = ({
    defaultAddress,
    address1,
    address2,
    city,
    state,
    zipcode,
    zipcodeFour,
    openDeleteModal,
    openEdit,
    isChecked,
    index,
    handleSetAsShipping,
    onSetAsDefault,
    addressType,
    isProfile,
    showLabel = true
}) => {
    const { t } = useTranslation();

    return (
        <Col className="mb-4 flex-fill">
            <div className="address-card-content border rounded rounded-2 border-sea-foam h-100">
                <Row className="d-flex pl-3 pr-3">
                    {handleSetAsShipping && (
                        <Col xs={2} className="p-3">
                            <section className="address-card--default-address mt-4">
                                <label className="default-address text-slate d-flex mb-0">
                                    <input
                                        type="radio"
                                        name="option"
                                        id={`address-${index}`}
                                        onChange={handleSetAsShipping}
                                        checked={isChecked}
                                    />
                                </label>
                            </section>
                        </Col>
                    )}
                    <Col xs={handleSetAsShipping ? 10 : 12} className="p-3">
                        <Row className="address-card d-flex flex-column pr-3 pl-3">
                            <div className="h6 d-flex address-card__eyebrow mb-2">
                                {addressType && showLabel && (
                                    <p className="address-card__type text-left mb-0">
                                        <small>
                                            {t('pages.profile.shippingAddress.addressType', {
                                                type: formatAddressType(addressType)
                                            })}
                                        </small>
                                    </p>
                                )}
                            </div>
                            <Row className="address-card--content h-100">
                                <Col xs={12} className={'align-self-start'}>
                                    <div className="h6 hero-eyebrow-text">
                                        {address1} {address2 ? address2 : ''}
                                    </div>
                                    <div className="h6 hero-eyebrow-text">
                                        {city}, {state} {zipcodeFour ? `${zipcode}-${zipcodeFour}` : zipcode}
                                    </div>
                                </Col>
                            </Row>

                            <div className="address-card__actions mt-4">
                                <Button
                                    className="address-card__action no-min-width pl-0 pr-1 pt-1 pb-1"
                                    variant="text"
                                    type="button"
                                    onClick={openEdit}
                                    dataGAFormName="address-card"
                                    label={t('shipping.editAddress')}
                                />
                                {isProfile && !defaultAddress && (
                                    <>
                                        <span className="divider">|</span>
                                        <Button
                                            className="address-card__action no-min-width p-1"
                                            variant="text"
                                            type="button"
                                            onClick={openDeleteModal}
                                            dataGAFormName="address-card"
                                            label={t('shipping.removeAddress')}
                                        />
                                    </>
                                )}
                                <span className="divider">|</span>
                                {defaultAddress ? (
                                    <span className="strong">{t('shipping.defaultAddressLabel')}</span>
                                ) : (
                                    onSetAsDefault && (
                                        <Button
                                            className="address-card__action no-min-width p-1"
                                            variant="text"
                                            type="button"
                                            onClick={onSetAsDefault}
                                            dataGAFormName="address-card"
                                            label={t('shipping.setAsDefault')}
                                        />
                                    )
                                )}
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Col>
    );
};

export default AddressCard;
